import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"

import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby-plugin-react-intl"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { useIntl } from "gatsby-plugin-react-intl"

//styles
import * as styles from "../../styles/components/blog/blog.module.scss"

const Blog = () => {
  const query = useStaticQuery(graphql`
    query MyQuery {
      allContentfulBlogPost(sort: { fields: date, order: DESC }) {
        edges {
          node {
            slug
            title
            date(formatString: "MMM DD, YYYY")
            image {
              gatsbyImageData(quality: 100, width: 500, height: 500)
            }
            shortdescription
          }
        }
      }
    }
  `)

  const allBlogPosts = query.allContentfulBlogPost.edges.map(edge => ({
    slug: edge.node.slug,
    image: edge.node.image.gatsbyImageData,
    title: edge.node.title,
    date: edge.node.date,
    shortdescription: edge.node.shortdescription,
  }))

  const intl = useIntl()

  return (
    <div>
      <div className={styles.welcome}>
        <StaticImage
          className={styles.image}
          src="../../images/blog/blogimage.jpg"
          alt="Welcome"
          layout="fullWidth"
        />
        <h1 className="text-center">
          {intl.formatMessage({ id: "welcomeBlog" })}
        </h1>
      </div>
      <Container className="my-5 py-5">
        <Row xs={1} className="g-5">
          {allBlogPosts.map((blogPost, idx) => (
            <Col xl={9} key={idx} className={`${styles.blogPost} mx-xl-auto `}>
              <Link to={`/blog/${blogPost.slug}`}>
                <Row className="align-items-center g-5">
                  <Col xs={12} md={6}>
                    <GatsbyImage image={blogPost.image} alt="" />
                  </Col>

                  <Col xs={12} md={6} className="mt-4 mt-md-0">
                    <Link to={`/blog/${blogPost.slug}`}>
                      <h1 className={styles.title}>{blogPost.title}</h1>
                      <p className="my-5 h4">
                        <strong>{blogPost.date}</strong>
                      </p>
                      <p className={`${styles.text} mb-5`}>
                        {" "}
                        {blogPost.shortdescription}
                      </p>
                    </Link>
                    <Link to={`/blog/${blogPost.slug}`} className={`py-3 `}>
                      <Button className={styles.btn}>
                        {intl.formatMessage({ id: "ReadMore" })}
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Blog
