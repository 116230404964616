import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Blog from "../components/blog/blog"

const IndexPage = () => (
  <Layout>
    <Seo title="Contact Us" description="Read our blog to learn more about our pediatric services, offered by Kids &amp; Teens Medical Group to the Glendale, CA community and surrounding areas. Read our posts or call our office at 818-244-9595 for more information." />
    <Blog />
  </Layout>
)

export default IndexPage
